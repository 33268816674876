import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom';
import { TextField, Box, Button, Typography, Card, CardContent, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import { doCreateUser } from "../../utils";
import mongoConfig from "../../mongo";
import * as Realm from "realm-web";

export const ConfirmInvitationForm = (props) => {
  const dispatch = useDispatch();
  const { invitationData, handleDeleteInvitation } = props;

  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [displayName, setDisplayName] = useState(invitationData.displayName || '');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  const handlePasswordChange = ({ target }) => {
    setNewPassword(target.value);
  };

  const handleDisplayNameChange = ({ target }) => {
    setDisplayName(target.value);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar;
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCreateAccountSubmit = async (target) => {
    target.preventDefault();
    setSubmitInProgress(true);
    setError(null);

    if (!invitationData || !invitationData.username || displayName === '' || newPassword === '') {
      setError('All fields are required.');
      setSubmitInProgress(false);
      return;
    }

    try {
      const app = new Realm.App({ id: mongoConfig.realmAppId });
      const credentials = Realm.Credentials.anonymous();
      const user = await app.logIn(credentials);

      const userDataRecord = {
        status: 'pending',
        timeZone: 'US/Central',
        displayName: displayName,
        role: invitationData.role,
        company: invitationData.company,
        username: invitationData.username,
      };

      if (invitationData.groupsAccess && invitationData.groupsAccess.length > 0) {
        userDataRecord['groupsAccess'] = invitationData.groupsAccess;
      }

      if (invitationData.officesAccess && invitationData.officesAccess.length > 0) {
        userDataRecord['officesAccess'] = invitationData.officesAccess;
      }

      await user.mongoClient(mongoConfig.realmServiceName)
          .db(mongoConfig.databaseName)
          .collection('usersdata')
          .insertOne(userDataRecord);

      await dispatch(doCreateUser({ email: userDataRecord.username, password: newPassword }));
      setUserCreated(true);
      // remove the user invitation record
      handleDeleteInvitation();
    } catch (err) {
      console.error('Create User Error: ', err);
      setError('Failed to create user. Please try again.');
    } finally {
      setSubmitInProgress(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6" align='center' paddingBottom={5}>You have been invited to join Ring Analytics</Typography>
      <Typography paddingBottom={3} align='center'>Please enter your full name, Fill in a password and Confirm your e-mail</Typography>
      <Card sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
        <CardContent>
          <form method="POST" onSubmit={handleCreateAccountSubmit}>
            {!userCreated && (
              <Grid container spacing={3}>
                <Grid size={12}>
                  <Grid container spacing={2}>
                    <Grid size={12}>
                      <TextField value={invitationData.username} disabled required fullWidth label="Email" name="username" size="medium" variant="outlined" slotProps={{
                        input: { readOnly: true, }
                      }} />
                    </Grid>
                    <Grid size={12} sx={{ marginBottom: 2 }}>
                      <TextField error={!displayName} onChange={handleDisplayNameChange} value={displayName} required fullWidth label="Full Name" name="displayName" size="medium" variant="outlined" />
                    </Grid>
                    <Grid size={12} sx={{ marginBottom: 2 }}>
                      <TextField
                        error={!validatePassword(newPassword)}
                        onChange={handlePasswordChange}
                        value={newPassword}
                        required
                        fullWidth
                        label="Your Password"
                        name="password"
                        size="medium"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        helperText={!validatePassword(newPassword) ? "Password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters." : ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {error && (
                  <Grid size={12} sx={{ marginBottom: 2 }}>
                    <Typography color="error">{error}</Typography>
                  </Grid>
                )}
                <Grid size={12} sx={{ marginBottom: 2 }}>
                  <Button
                    disabled={!displayName || !validatePassword(newPassword) || submitInProgress}
                    color="secondary" fullWidth type="submit" variant="contained"
                  >
                    {submitInProgress ? 'Creating User. Please wait' : 'Confirm my Email'}
                  </Button>
                </Grid>
                
              </Grid>
            )}
            {userCreated && (
              <Grid container spacing={3}>
                <Grid size={12}>
                  <Grid container spacing={2}>
                    <Grid size={12} sx={{ textAlign: 'center' }}>
                      <Typography>Please check your email to complete registration.</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </form>
        </CardContent>
      </Card>
      <Grid container justifyContent="center" alignItems="center" size={12} sx={{ marginTop: 8 }}>
        <NavLink to="/login">
          I already have an account, Go to Login Screen
        </NavLink>
      </Grid>
    </Box>
  );
};