import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    officeList: null,  // The initial state for the office list
    loading: false,    // To track the loading state
    error: null        // To track any errors
};

const officeSlice = createSlice({
    name: 'offices',
    initialState,
    reducers: {
        setOffices(state, action) {
            state.officeList = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        updateOfficeValue: (state, action) => {
            const { _id, ...updatedFields } = action.payload;
            const officeIndex = state.officeList.findIndex((office) => office._id === _id);
            if (officeIndex !== -1) {
                state.officeList[officeIndex] = {
                ...state.officeList[officeIndex],
                ...updatedFields, // Only update the fields that were changed
                };
            }
        }
    }
});

// Export the actions to be used in components
export const { setOffices, setLoading, setError, officeList, updateOfficeValue } = officeSlice.actions;

// Export the reducer to be included in the store
export default officeSlice.reducer;
