import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    groupsList: null,  // The initial state for the groups list
    loading: false,    // To track the loading state
    error: null        // To track any errors
};

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setGroups(state, action) {
            state.groupsList = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

// Export the actions to be used in components
export const { setGroups, setLoading, setError } = groupsSlice.actions;

// Export the reducer to be included in the store
export default groupsSlice.reducer;
