import React, { useState, useEffect } from 'react';
import * as Realm from "realm-web";
import ObjectID from 'bson-objectid';
import mongoConfig from '../mongo';
import {doEmailUserInvitation} from "../utils";
import {useDispatch} from "react-redux";
import makeStyles from '@mui/styles/makeStyles';

import { Container, Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ConfirmInvitationForm } from "../components/Auth/ConfirmInvitationForm";
import { InRefreshPage } from './InRefreshPage';

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: '100%',
    display: 'flex',
    margin: '2em auto'
  }
}));

function deleteUserInvitation(invitationID) {
  const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
  const anonymousCredentials = Realm.Credentials.anonymous();

  currentApp.logIn(anonymousCredentials).then(anonymousUser => {
    anonymousUser
        .mongoClient(mongoConfig.realmServiceName)
        .db(mongoConfig.databaseName)
        .collection('usersinvitations')
        .deleteOne({ _id: ObjectID(invitationID) })
        .then(() => {
          console.log('Invitation deleted successfully');
        })
        .catch(err => {
          console.error('Error deleting invitation: ', err);
        });
  });
}
export const EmailedInvitationPage = (props) => {
  const classes = useStyles();

  const [anonymousUser, setAnonymousUser] = useState(undefined);
  const [invitationObj, setInvitationObj] = useState(undefined);
  const [invitationID, setInvitationID] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const decodeSafelink = (url) => {
    const safelinkPrefix = "https://safelinks.protection.outlook.com/";
    if (url.startsWith(safelinkPrefix)) {
      const urlParams = new URLSearchParams(url);
      return urlParams.get('url') || url;
    }
    return url;
  };

  const handleDeleteInvitation = () => {
    if (invitationObj) {
      deleteUserInvitation(invitationObj._id.toString());
    }
  }

  useEffect(() => {
    if (props.invitationid) {
      const decodedToken = decodeURIComponent(decodeSafelink(props.invitationid));
      setInvitationID(decodedToken);
    }
  }, [props.invitationid]);

  useEffect(() => {
    if (invitationID) {
      setLoading(true);
      try {
        const currentApp = Realm.App.getApp(mongoConfig.realmAppId);
        const anonymousCredentials = Realm.Credentials.anonymous();

        currentApp.logIn(anonymousCredentials).then(anonymousUser => {
          setAnonymousUser(anonymousUser);
          setLoading(false);
        }, reason => {
          setError('Token validation failed. Please request a new invitation.');
          setLoading(false);
          console.error(reason);
        });

      } catch (err) {
        setError('Token validation failed.');
        setLoading(false);
        console.error(err);
      }
    }
  }, [invitationID]);

  useEffect(() => {
    if (anonymousUser) {
      anonymousUser
          .mongoClient(mongoConfig.realmServiceName)
          .db(mongoConfig.databaseName)
          .collection('usersinvitations')
          .findOne({ _id: ObjectID(invitationID) })
          .then(invitationData => {
            setInvitationObj(invitationData);
          })
          .catch(err => {
            setError('Failed to load invitation data');
            console.error(err);
          });
    }
  }, [anonymousUser, invitationID]);

  const dispatch = useDispatch();

  const handleResendInvitation = () => {
    if (invitationObj) {
      const userDetails = {
        _id: invitationObj._id.toString(),
        email: invitationObj.username,
        name: invitationObj.displayName,
        role: invitationObj.role,
        company: invitationObj.company.toUpperCase(),
      };

      dispatch(doEmailUserInvitation(anonymousUser, userDetails))
          .then(() => {
            console.log('Invitation resent successfully');
          })
          .catch((err) => {
            console.error('Error resending invitation: ', err);
            setError('Failed to resend invitation');
          });
    } else {
      setError('No invitation data available to resend');
    }
  }

  if (loading) {
    return <InRefreshPage />;
  }

  if (error) {
    return (
        <Container maxWidth="xs" className={`${classes.background} ${classes.container}`}>
          <Box my={4}>
            <Typography color="error">{error}</Typography>
            <Button variant="contained" color="primary" onClick={handleResendInvitation}>
              Resend Invitation
            </Button>
          </Box>
        </Container>
    );
  }

  return (invitationObj === undefined) ? (
      <InRefreshPage />
  ) : (
      <Container maxWidth="sm">
        <Box my={4}>
          <Box>
            <Grid container spacing={3} justifyContent="center">
              <Grid xs={12}>
                <img className={classes.logo} src="/img/ringanalytics-brand.png" alt="Ring Analytics" title="Ring Analytics" />
              </Grid>
            </Grid>
            {(invitationObj !== null) && <ConfirmInvitationForm invitationData={invitationObj} handleDeleteInvitation={handleDeleteInvitation} />}
          </Box>
        </Box>
        <Box display="flex" height={1} />
      </Container>
  );
};